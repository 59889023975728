import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useAuth } from "./useAuth";
import db from "./db";
import useScanConv from "./useScanConv";

export function Codes() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [codesSet, setCodesSet] = useState(new Set());
  const hEr = er => db.put('ErS' + Date.now(), er);
  const fScanConv = useScanConv();
  const hScan = ev => fScanConv(ev, sCode => {
    // console.log(sCode);
    const sScanCode = (sCode).slice(0, 31);
    ev.target.form.scanCode.value = '';
    if (sScanCode.slice(0, 2) != '01' || sScanCode.slice(16, 18) != '21') return;
    setCodesSet(prev => new Set(prev.add(sScanCode)));    
  });

  function convRuToEn(sData) {
    const aData = [...sData];
    if (!aData.find(sChar => sChar.charCodeAt(0) > 0x400)) return sData;
    return aData.map(sChar => {
      let nCharCode = sChar.charCodeAt(0);
      // switch (true) {
      // }
      return String.fromCharCode(nCharCode) + '(' +nCharCode + ')';
    }).join('');
  }

  function iconv(data) {
    let wrt = typeof data == 'string', arr = wrt ? [...data].map(ch => ch.charCodeAt(0)) : data;
  
    arr = arr.map(cc => {
      switch (true) {
        case cc == 1025: cc = 240; break;
        case cc == 8470: cc = 36; break;
        case cc == 8209: cc = 250; break;
        case cc == 8364: cc = 242; break;
        case cc == 9632: cc = 255; break;
        case 1039 < cc && cc < 1088: cc -= 912; break;
        case 1087 < cc && cc < 1106: cc -= 864; break;
        case cc == 36: cc = wrt ? 252 : 8470; break;
        case cc == 240: cc = 1025; break;
        case cc == 252: cc = 36; break;
        case cc == 250: cc = 8209; break;
        case cc == 242: cc = 8364; break;
        case cc == 255: cc = 9632; break;
        case 127 < cc && cc < 176: cc += 912; break;
        case 223 < cc && cc < 242: cc += 864; break;
      }
      return wrt ? cc : String.fromCharCode(cc);
    })
    return wrt ? arr : arr.join('');
  }
  
  const hSend = ev => {
    // return console.log('log' + Date.now());
    db.get('reserved').then(resp => {
      const sArea = 'area' + user.idArea;
      resp.list[sArea] = Array.from(codesSet);
      db.put('reserved', resp).then(res => {
        db.post('', {
          _id: 'log' + Date.now(),
          user: user.id,
          // area: user.idArea,
          action: 'scan',
          codes: resp.list[sArea]
        }).catch(hEr);
        navigate("/success", { replace: true })
      }, hEr);
    }, hEr);
  }

  return <Box sx={{ m: 0 }}>
    <Typography sx={{ pt: 2, px: 2 }}>
      <span style={{ color: 'red' }}>Внимание! </span>
      При получении партии с цеха воды необходимо сканировать ВСЕ марки, включая остатки с прошлой партии
    </Typography>
    <Typography sx={{ p: 2 }}>
      Количество отсканированных кодов: {codesSet.size}
      <Button
        variant="contained"
        onClick={() => setCodesSet(new Set())}
        sx={{ m: 2 }}
        disabled={!codesSet.size}
        startIcon={<DeleteIcon />}
      >
        Очистить
      </Button>
      <Button
        variant="contained"
        onClick={hSend}
        sx={{ m: 2 }}
        disabled={!codesSet.size}
        endIcon={<SendIcon />}
      >
        Отправить в цех воды
      </Button>
    </Typography>
    <form onSubmit={ev => ev.preventDefault()} style={{ position: 'fixed', opacity: 0 }}>
      <input
        autoFocus
        autoComplete="off"
        name='scanCode'
        onKeyDown={hScan}
        // onBlur={ev => console.log(ev.target)}
        onBlur={ev => setTimeout(() => ev.target.select(), 10)}
        //
      />
      <input type="submit" />
    </form>
    <List sx={{ flexGrow: 1, maxWidth: 500 }}>
      {Array.from(codesSet).map(sCode => <ListItem
        key={sCode}
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => setCodesSet(prev => {
              prev.delete(sCode);
              return new Set(prev);
            })}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: '#fcff25', color: 'black' }} >
            <QrCode2Icon/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={sCode} />
      </ListItem>)}
    </List>
  </Box>
}
