import React, { useState, useEffect } from 'react';

export default function useScanConv() {
  const [nTimerId, setTimerId] = useState();
  const [sCode, setCode] = useState('');

  return function(ev, cbFn) {
    let nKeyCode = ev.keyCode;
    // console.log(nKeyCode);
    if (nKeyCode < 28) return;
    clearTimeout(nTimerId);
    const bShift = ev.shiftKey;
    switch (true) {
      case ev.code == 'Minus': nKeyCode = bShift ? 95 : 45; break;
      case ev.code == 'Equal': nKeyCode = bShift ? 43 : 61; break;
      case ev.code == 'Semicolon': nKeyCode = bShift ? 58 : 59; break;
      case ev.code == 'Slash': nKeyCode = bShift ? 63 : 47; break;
      case nKeyCode == 192: nKeyCode = bShift ? 126 : 96; break;
      case nKeyCode == 222: nKeyCode = bShift ? 34 : 39; break;
      case bShift && nKeyCode == 48: nKeyCode = 41; break;
      case bShift && nKeyCode == 50: nKeyCode = 64; break;
      case bShift && nKeyCode == 54: nKeyCode = 94; break;
      case bShift && nKeyCode == 56: nKeyCode = 42; break;
      case bShift && nKeyCode < 54: nKeyCode -= 16; break;
      case bShift && nKeyCode < 58: nKeyCode -= 17; break;
      case bShift && nKeyCode > 218: nKeyCode -= 96; break;
      case nKeyCode > 187: nKeyCode -= 128; break;
      case !bShift && nKeyCode > 64: nKeyCode += 32; break;
    }
    const sNewCode = sCode + String.fromCharCode(nKeyCode);
    setCode(sNewCode);
    setTimerId(setTimeout(() => {
      setCode('');
      cbFn(sNewCode);
    }, 200));
  }
}